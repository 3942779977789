


/*===========================
        04.ABOUT css
===========================*/

.about_area{
    position: relative;

    @media #{$lg}{
        margin-top: 100px;
    }
}

.about_shape{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}

.about_image{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;

    @media #{$md}{
        position: relative;
        width: 720px;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
    }
    @media #{$xs}{
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media #{$sm}{
        width: 540px;
    }

    & .image{
        padding: 20px;
        & img{

        }
    }
}

.about_content{
    padding-top: 150px;
    padding-bottom: 50px;

    @media #{$lg}{
        padding: 0;
    }
    @media #{$md}{
        padding-top: 45px;
        padding-bottom: 0;
    }
    @media #{$xs}{
        padding-top: 45px;
        padding-bottom: 0;
    }
}







