


/*===========================
        10.FOOTER css
===========================*/

.footer_area{
    position: relative;
}




.footer_about{
    & a{}
    & p{
        margin-top: 25px;
    }
    & .social{
        margin-top: 20px;

        & li{
            display: inline-block;
            margin-right: 15px;

            & a{
                font-size: 20px;
                color: $body-color;
                @include transition(0.3s);

                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
}

.footer_title{
    font-size: 30px;
    font-weight: 600;

    @media #{$lg}{
        font-size: 24px;
    }
    @media #{$xs}{
        font-size: 24px;
    }
}

.footer_link_wrapper{
    & .footer_link{
        width: 33.33%;
        padding-left: 50px;

        @media #{$lg}{
            padding-left: 0;
        }
        @media #{$xs}{
            width: 50%;
            padding-left: 0;
        }

        & .link{
            padding-top: 10px;

            & li{
                margin-top: 10px;

                & a{
                    font-size: 16px;
                    color: $body-color;
                    @include transition(0.3s);

                    &:hover{
                        color: $theme-color;
                    }
                }
            }
        }
    }
}


.footer_copyright{
    & .copyright{
        border-top: 2px solid rgba($heading-color, 0.14);
        padding: 25px 0;

        & p{

        }
    }
}

/*===== BACK TO TOP =====*/

.back-to-top{
    font-size: 20px;
    color: $white;
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 45px;
    height: 45px;
    line-height: 50px;
    border-radius: 5px;
    background-color: $theme-color;
    text-align: center;
    z-index: 99;
    @include transition(0.3s);
    display: none;

    &:hover{
        color: $white;
        background-color: $theme-color;
    }
}

















