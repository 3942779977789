// 1: Initialize
@import "../../../node_modules/bootstrap/scss/mixins";
@import "variables.override";
@import "variables.bootstrap";

// 2: Bootstrap framework includes
// Relative to app/assets/stylesheets (the sass_dir in _config.yml)
@import "../../../node_modules/bootstrap/scss/bootstrap";

// 3: Main layout
@import "layout";

// 4: Utility functions
// @import "functions/theme_colors";

// 5: Components

@import "components/mixins/buttons";
@import "components/mixins/svg_icon";

// @import "components/buttons";
@import "components/logo";
@import "components/sub_navbar";

// 6: Public website specific

@import 'variables';
@import 'mixin';
@import 'common';
@import 'header';
@import 'features';
@import 'about';
@import 'video';
@import 'gallery';
@import 'client';
@import 'blog';
@import 'text_page';
@import 'contact';
@import 'footer';

#features {
  padding-top: 20vh;
}
