


/*===========================
        02.HEADER css
===========================*/


/*===== NAVBAR =====*/


/*Vertical Sliding*/
.header_rotating-word{
	display: inline;
	text-indent: 8px;
}
.header_rotating-word span{
	animation: topToBottom 20s linear infinite 0s;
	-ms-animation: topToBottom 20s linear infinite 0s;
	-webkit-animation: topToBottom 20s linear infinite 0s;
	color: #00abe9;
	opacity: 0;
	overflow: hidden;
	position: absolute;
}
.header_rotating-word span:nth-child(2){
	animation-delay: 2.5s;
	-ms-animation-delay: 2.5s;
	-webkit-animation-delay: 2.5s;
}
.header_rotating-word span:nth-child(3){
	animation-delay: 5s;
	-ms-animation-delay: 5s;
	-webkit-animation-delay: 5s;
}
.header_rotating-word span:nth-child(4){
	animation-delay: 7.5s;
	-ms-animation-delay: 7.5s;
	-webkit-animation-delay: 7.5s;
}
.header_rotating-word span:nth-child(5){
	animation-delay: 10s;
	-ms-animation-delay: 10s;
	-webkit-animation-delay: 10s;
}
.header_rotating-word span:nth-child(6){
	animation-delay: 12.5s;
	-ms-animation-delay: 12.5s;
	-webkit-animation-delay: 12.5s;
}
.header_rotating-word span:nth-child(7){
	animation-delay: 15s;
	-ms-animation-delay: 15s;
	-webkit-animation-delay: 15s;
}
.header_rotating-word span:nth-child(8){
	animation-delay: 17.5s;
	-ms-animation-delay: 17.5s;
	-webkit-animation-delay: 17.5s;
}

@-moz-keyframes topToBottom{
	0% { opacity: 0; }
	3% { opacity: 0; -moz-transform: translateY(-50px); }
	6% { opacity: 1; -moz-transform: translateY(0px); }
	12% { opacity: 1; -moz-transform: translateY(0px); }
	15% { opacity: 0; }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

@-webkit-keyframes topToBottom{
	0% { opacity: 0; }
	3% { opacity: 0; -moz-transform: translateY(-50px); }
	6% { opacity: 1; -moz-transform: translateY(0px); }
	12% { opacity: 1; -moz-transform: translateY(0px); }
	15% { opacity: 0; }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

@-ms-keyframes topToBottom{
	0% { opacity: 0; }
	3% { opacity: 0; -moz-transform: translateY(-50px); }
	6% { opacity: 1; -moz-transform: translateY(0px); }
	12% { opacity: 1; -moz-transform: translateY(0px); }
	15% { opacity: 0; }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

.header_navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    @include transition(0.3s);
}


.sticky {
    position: fixed;
    z-index: 999;
    background-color: $white;
    @include box-shadow (0px 20px 50px 0px rgba(0, 0, 0, 0.05));
    @include transition(0.3s);

    & .navbar {
        padding: 10px 0;
    }
}


.navbar {
    padding: 25px 0;
    border-radius: 5px;
    position: relative;
    @include transition(0.3s);
}

.navbar-brand {
    padding: 0;
}


.navbar-toggler {
    padding: 0;

    & .toggler-icon {
        width: 30px;
        height: 2px;
        background-color: $heading-color;
        display: block;
        margin: 5px 0;
        position: relative;
        @include transition(0.3s);
    }

    &.active {
        & .toggler-icon {
            &:nth-of-type(1) {
                @include transform(rotate(45deg));
                top: 7px;
            }

            &:nth-of-type(2) {
                opacity: 0;
            }

            &:nth-of-type(3) {
                @include transform(rotate(135deg));
                top: -7px;
            }
        }
    }
}


.navbar-collapse {
    @media #{$md} {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $white;
        z-index: 9;
        @include box-shadow (0px 15px 20px 0px rgba($black, 0.1));
        padding: 5px 12px;
    }

    @media #{$xs} {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $white;
        z-index: 9;
        @include box-shadow (0px 15px 20px 0px rgba($black, 0.1));
        padding: 5px 12px;
    }
}


.navbar-nav {
    & .nav-item {
        margin-left: 45px;
        position: relative;

        @media #{$lg} {
            margin-left: 30px;
        }

        @media #{$md} {
            margin: 0;
        }

        @media #{$xs} {
            margin: 0;
        }

        & a {
            font-size: 16px;
            font-weight: 600;
            color: $heading-color;
            @include transition(0.3s);
            padding: 5px 0;
            position: relative;

            &::before{
                position: absolute;
                content: '';
                width: 0;
                height: 2px;
                background-color: $heading-color;
                border-radius: 50px;
                left: 110%;
                left: 0;
                bottom: 0;
                @include transition(0.3s);

                @media #{$md} {
                    display: none;
                }
                @media #{$xs} {
                    display: none;
                }
            }

            @media #{$md} {
                display: block;
                padding: 4px 0;
            }

            @media #{$xs} {
                display: block;
                padding: 4px 0;
            }
        }

        &.active,
        &:hover {
            & >a {
                &::before{
                    width: 100%;
                }
            }
        }

        &:hover {
            & .sub-menu {
                top: 100%;
                opacity: 1;
                visibility: visible;

                @media #{$md} {
                    top: 0;
                }

                @media #{$xs} {
                    top: 0;
                }
            }
        }

        & .sub-menu {
            width: 200px;
            background-color: $white;
            @include box-shadow (0px 0px 20px 0px rgba(0, 0, 0, 0.1));
            position: absolute;
            top: 110%;
            left: 0;
            opacity: 0;
            visibility: hidden;
            @include transition(0.3s);

            @media #{$md} {
                position: relative;
                width: 100%;
                top: 0;
                display: none;
                opacity: 1;
                visibility: visible;
            }

            @media #{$xs} {
                position: relative;
                width: 100%;
                top: 0;
                display: none;
                opacity: 1;
                visibility: visible;
            }

            & li {
                display: block;

                & a {
                    display: block;
                    padding: 8px 20px;
                    color: $black;

                    &.active,
                    &:hover {
                        padding-left: 25px;
                        color: $theme-color;
                    }
                }
            }
        }
    }

    & .sub-nav-toggler {
        display: none;

        @media #{$md} {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            background: none;
            color: $black;
            font-size: 18px;
            border: 0;
            width: 30px;
            height: 30px;
        }

        @media #{$xs} {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            background: none;
            color: $black;
            font-size: 18px;
            border: 0;
            width: 30px;
            height: 30px;
        }

        & span {
            width: 8px;
            height: 8px;
            border-left: 1px solid $black;
            border-bottom: 1px solid $black;
            @include transform(rotate(-45deg));
            position: relative;
            top: -5px;
        }
    }
}


/*===== HEADER HERO =====*/

.header_hero {
    position: relative;
    z-index: 5;
    background-position: bottom center;
    overflow: hidden;
    min-height: 90vh;

    @media #{$desktop}{
        min-height: 90vh;
    }

    @media #{$lg}{
        min-height: 90vh;
    }
    @media #{$md}{
        height: auto;
    }
    @media #{$xs}{
        height: auto;
    }
}


.header_image,
.header_shape{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-position: left bottom;
}

.header_image{
    z-index: 9;

    @media #{$md}{
        position: relative;
        width: 720px;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 50px;
    }
    @media #{$xs}{
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 50px;
    }

    @media #{$sm}{
        width: 540px;
    }

    & .image{
        position: relative;
        padding-left: 50px;

        .dots{
            position: absolute;
            left: 17%;
            bottom: 0;
            z-index: -1;

            @media #{$lg}{
                width: 125px;
            }
            @media #{$xs}{
                width: 100px;
            }
            @media #{$sm}{
                width: 130px;
            }
        }
        & img{

        }
    }
}


.header_hero_content {

    @media #{$md}{
        padding-top: 50px;
    }

    & .header_title{
        font-size: 50px;
        font-weight: 700;
        color: $black;
        margin-top: 20px;

        @media #{$lg}{
            font-size: 40px;
        }
        @media #{$xs}{
            font-size: 24px;
        }
        @media #{$sm}{
            font-size: 30px;
        }

        & span{
            color: $theme-color;
        }
    }

    & p{
        margin-top: 15px;
    }
    & .main-btn{
        margin-top: 30px;
    }
}


.header_hero_image {
    & img{
        width: 100%;
    }
}







