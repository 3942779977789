


/*===========================
      06.GALLERY css 
===========================*/

.gallery_area{}


.single_gallery{
    padding: 30px;
    border: 2px solid $border-color;
    border-radius: 25px;
    @include transition(0.3s);
    
    @media #{$sm}{
        padding: 20px;
    }
    
    & .gallery_sticker{
        
        & .discount{
            width: 72px;
            height: 38px;
            line-height: 38px;
            border-radius: 50px;
            background-color: #F35353;
            font-size: 16px;
            color: $white;
            text-align: center;
            float: right;
        }
        & .new{
            width: 72px;
            height: 38px;
            line-height: 38px;
            border-radius: 50px;
            background-color: $theme-color;
            font-size: 16px;
            color: $white;
            text-align: center;
            float: left;
        }
    }
    & .gallery_image{
        margin-top: 30px;
        
        & img{
            width: 100%;
        }
    }
    & .gallery_content{
        background-color: $white;
        position: relative;
        z-index: 5;
        
        & ul{
            & li{
                display: inline-block;
                color: $theme-color;
                font-size: 16px;
            }
        }
        & .gallery_title{
            & a{
                font-size: 24px;
                font-weight: 600;
                color: $heading-color;
                @include transition(0.3s);
                margin-top: 10px;
                
                @media #{$lg}{
                    font-size: 20px;
                }
                @media #{$xs}{
                    font-size: 20px;
                }
                
                &:hover{
                    color: $theme-color;
                }
            }
        }
        & .price{
            margin-top: 10px;
            
            & .discount_price{
                font-size: 24px;
                font-weight: 600;
                color: $theme-color;
                margin-right: 5px;
                
                @media #{$lg}{
                    font-size: 20px;
                }
                @media #{$xs}{
                    font-size: 20px;
                }
            }
            & .regular_price{
                font-size: 24px;
                font-weight: 400;
                color: $body-color;
                position: relative;
                margin-left: 5px;
                
                @media #{$lg}{
                    font-size: 20px;
                }
                
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: $body-color;
                    top: 50%;
                    left: 0;
                    @include transform(translateY(-50%));

                }
            }
        }
    }
    & .gallery_btn{
        margin-top: -60px;
        @include transition(0.3s);
        opacity: 0;
        
        & .main-btn{
            padding: 0 30px;
        }
    }
    
    &:hover{
        border-color: $theme-color;
        
        & .gallery_btn{
            margin-top: 30px;
            opacity: 1;
        }
    }
}

.slick-center{
    & .single_gallery{
        border-color: $theme-color;
        
        & .gallery_btn{
            margin-top: 30px;
            opacity: 1;
        }
    }
}















