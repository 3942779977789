


/*===========================
      04.VIDEO css
===========================*/

.video_area{
    position: relative;
    padding-top: 45px;

    & .video_shape{
        position: absolute;
        top: 270px;
        width: 100%;
        left: 0;

        & img{
            width: 100%;
        }

        @media #{$md}{
          display: none;
        }
        @media #{$xs}{
          display: none;
        }
    }
}


.video_image{
    position: relative;

    & img{
        width: 100%;
        border-radius: 10px;
    }

    & .video_play{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        line-height: 55px;
        border-radius: 50%;
        background-color: $white;
        color: $theme-color;
        font-size: 18px;
        text-align: center;
        @include transform(translate(-50%, -50%));
    }
}










