@include media-breakpoint-up(lg) {
  .sub-navbar {
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    border-bottom: 2px solid $border-color;

    // Item
    .nav-item {
      flex-grow: 1;
        flex-basis: 0;

       // Link
      .nav-link {
        @include border-radius($border-radius);
        padding: 0;
        display: flex;
        flex-grow: 1;
        align-items: stretch;
        flex-direction: column;
        border-radius: 0;
        padding-left: 2rem;
        border-right: 2px solid $gray-300;

        &.active {
          .nav-text {
            color: $primary;
          }
        }

        .nav-text {
          color: $dark-75;
          font-weight: 500;
          font-size: 1.35rem;
        }

        .nav-desc {
          margin-top: 0.35rem;
          color: $text-muted;
          font-weight: 500;
          font-size: 1rem;
        }

        .nav-arrow {
          color: $text-muted;
        }

        .nav-icon {
          i {
            color: $text-muted;
          }

          // .svg-icon {
          //   @include svg-icon-color($text-muted);
          // }
        }
      }

      &:first-child {
        > .nav-link {
          padding-left: 0;
        }
      }

      &:last-child {
        > .nav-link {
          border-right: 0;
        }
      }

      // Hover States
      &:hover:not(.nav-item-here):not(.nav-item-active),
      &.nav-item-hover:not(.nav-item-here):not(.nav-item-active) {
        background-color: transparent;

        .nav-text {
          color: $primary;
        }

        .nav-arrow {
          color: rgba($primary, 0.8);
        }

        .nav-icon {
          i {
            color: rgba($primary, 0.8);
          }

          // .svg-icon {
          //   @include svg-icon-color(rgba($primary, 0.8));
          // }
        }
      }
    }
  }

  .sub-navbar-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    height: 100%;
  }
}

// Above 992px and below 1400px breakpoints
@include media-breakpoint-between(lg, xl) {
	// Header Menu
	.sub-navbar {
		// Header Nav
		.nav {
			> .nav-item {
				> .nav-link {
					padding-left: 1rem;
				}

				&:first-child {
					> .nav-link {
						padding-left: 0;
					}
				}

				&:last-child {
					> .nav-link {
						border-right: 0;
					}
				}
			}
		}
	}

  .sub-navbar-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    height: 100%;
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  .sub-navbar-wrapper {
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);
  }

	.sub-navbar{
    .container {
      flex-direction: column;
    }
    .nav-item {
      > .nav-link {
        padding-top: 1rem;
        .nav-desc {
          display: none;
        }

        &.active {
          .nav-text {
            color: $primary;
          }
          &::before {
            display: block;
            left: 0;
            top: 8px;
            bottom: 8px;
            position: absolute;
            width: 4px;
            background-color: transparent;
            transition: all 0.15s ease;
            border-radius: 2px;
            content: " ";
          }
        }

        .nav-text {
          color: $dark-75;
          font-weight: 500;
          font-size: 1.35rem;
        }
      }
    }
	}

  .header-open {
    .sub-navbar-wrapper {
      left: 0px;
    }
  }

  .header-bottom {
    height: 0px
  }

  .navbar {
    height: 100%;
    display: block;
  }

  .sub-navbar-wrapper {
    z-index: 1001;
    position: fixed;
    top: 0;
    height: 100vh;
    left: -295px;
    width: 275px;
  }
}
