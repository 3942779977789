//
// Override global variables
//

// Theme colors
$primary:       									#2D728F;
$primary-hover:    									#81B2C8;
$primary-light:    									lighten($primary,40%);
$primary-inverse:  									#FFFFFF;

$warning: #EDAE49;
$warning-hover: #ECAE49;

$danger: #ED6A5A;

$root-font-size: 13px;
$root-font-size-lg: 12px;
$root-font-size-md: 12px;

