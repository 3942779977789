// Font Family
$font-1: 'Josefin Sans', sans-serif;

// Theme Color
$theme-color: $primary;


//Body Color
$body-color: #747E88;


// Border Color
$border-color:#ECECEC;
//

// Heading Color
$heading-color: $gray-800;
//

// Soft color
$black-soft: $gray-900;


// Responsive Variables
$desktop: 'only screen and (min-width: 1400px)';
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';


