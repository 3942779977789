.text-page {
    section {
        margin-top: 40px;
    }

    h3 {
        padding-bottom: 30px;
    }

    p {
        padding-bottom: 20px;
    }

    ul {
        padding-bottom: 20px;
    }

    ol {
        padding-bottom: 20px;
    }
}
