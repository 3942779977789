.navbar-brand {
  .logo {
    display: inline-block;
    height: 100%;
    padding: 5px;
    @include border-radius($border-radius);
    &:hover {
      background: rgba(255,255,255,0.3);
    }
  }
}
